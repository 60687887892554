import { API_ENDPOINTS } from '../utils/endpoints'
import { callGraphQL, makeAPIRequest } from '../utils/request'
import {
  eventInfoAndStatsById,
  liveEventInfoAndStats,
  broadcastLiveEventInfoAndStats,
  playLogsByEvent,
  eventInProgressBySlug,
} from './events.queries'
import { EventGQL, EventResponse, EventPlayLogsResponse } from './events.types'

/**
 * Fetch all event info for specific season
 */
export async function fetchSeasonEvents(
  season: number,
  includeCS?: boolean
): Promise<EventGQL[] | null> {
  try {
    let evRes = await makeAPIRequest(API_ENDPOINTS.SEASON_EVENTS, {
      method: 'GET',
      apiVersion: '4',
      queryParams: {
        year: season,
        includeCS: includeCS || false,
      },
    })
    if (evRes?.error) {
      evRes.error?.message && console.error(evRes.error?.message)
      return null
    }
    return evRes?.data
  } catch (error) {
    return null
  }
}

/**
 * Fetch event info for particular officialId
 */
export async function fetchEventByIdGQL(id: string) {
  const ev = await callGraphQL<EventResponse>(eventInfoAndStatsById, {
    id: id,
  })
  return ev.event
}

/**
 * Fetch event info for live game
 */
export async function fetchLiveEventByIdGQL(id: string) {
  const ev = await callGraphQL<EventResponse>(liveEventInfoAndStats, {
    id: id,
  })
  return ev.event
}

/**
 * Fetch event info for particular officialId
 */
export async function fetchEventInProgressBySlugGQL(
  slug: string
): Promise<EventResponse | null> {
  try {
    return await callGraphQL<EventResponse>(eventInProgressBySlug, {
      slug: slug,
    })
  } catch (error) {
    return null
  }
}

/**
 * Fetch event info for live game
 */
export async function fetchLiveEventBroadcastViewer(id: string) {
  const ev = await callGraphQL<EventResponse>(broadcastLiveEventInfoAndStats, {
    id: id,
  })
  return ev.event
}

/**
 * Fetch play logs for a single game
 */
export async function fetchPlayLogsByEvent(eventId: string) {
  const ev = await callGraphQL<EventPlayLogsResponse>(playLogsByEvent, {
    eventId,
  })
  return ev.playLogs
}
