import { SeasonType } from '../../Routing/urls'
import { callGraphQL } from '../utils/request'
import {
  allPlayersWithStatsQuery,
  fetchAllTimeLeaderByStat,
  fetchAllPlayersAdvancedStats,
  playerFantasyStatsQuery,
  allStatLeadersQuery
} from './players.queries'
import type {
  AllPlayersResponse,
  PlayerData,
  AllTimeLeaderResponse,
  AllTimePlayerInfo,
  AllPlayersAdvancedStatsResponse,
  PlayerWithAdvancedStatsData,
  LeagueLeader,
  LeagueLeadersResponse,
  AllPlayersFantasyResponse,
} from './players.types'

export async function getAllPlayers(
  season: number,
  seasonSegment: SeasonType,
  limit?: number
): Promise<PlayerData[]> {
  const data = await callGraphQL<AllPlayersResponse>(allPlayersWithStatsQuery, {
    season,
    includeZPP: seasonSegment === 'champseries',
    includeReg: seasonSegment === 'regular',
    includePost: seasonSegment === 'post',
    limit,
  })
  return data.allPlayers
}

/**
 * Fetch team leaders by stat
 */
export async function fetchLeagueLeaders(
  year: number,
  seasonSegment: string,
  statList: string[],
  limit: number = 3
): Promise<LeagueLeader[]> {
  let leaders = await callGraphQL<LeagueLeadersResponse>(allStatLeadersQuery, {
    year,
    seasonSegment,
    statList,
    limit,
  })

  if (!leaders?.playerStatLeaders) {
    return []
  }
  return leaders.playerStatLeaders
}

/**
 * Fetch all time stats leaders
 */
export async function fetchAllTimeLeaderByStatGQL(
  stat: string,
  limit: number
): Promise<AllTimePlayerInfo[]> {
  const data = await callGraphQL<AllTimeLeaderResponse>(fetchAllTimeLeaderByStat, {
    stat: stat,
    limit: limit,
  })
  return data.careerStats
}

/**
 * Fetch all time stats leaders
 */
export async function fetchAllPlayersAdvancedStatsGQL(
  year: number,
  limit: number = 250
): Promise<PlayerWithAdvancedStatsData[]> {
  try {
    const data = await callGraphQL<AllPlayersAdvancedStatsResponse>(
      fetchAllPlayersAdvancedStats,
      {
        year,
        limit,
      }
    )
    return data?.allPlayers || []
  } catch (err) {
    console.log(err)
    return []
  }
}

/**
 * Fetch all time stats leaders
 */
export async function fetchAllPlayersFantasyStats(
  year: number,
  week: number
): Promise<PlayerData[]> {
  const data = await callGraphQL<AllPlayersFantasyResponse>(playerFantasyStatsQuery, {
    season: year,
    week: week,
  })

  let filteredPlayers = data.allPlayerFantasy.filter(
    (plr) => plr.team?.officialId !== 'ZPP'
  )
  return filteredPlayers
}