import { PlayLogs } from '../Api'

export type PlayLogEventType =
  | 'shot'
  | 'faceoff'
  | 'groundball'
  | 'penalty'
  | 'powerplayend'
  | 'turnover'
  | 'shotclockexpired'
  | 'ballcleared'
  | 'timeoutcalled'
  
export type PlayLogDescriptorType =
  | 'SHOT GOOD'
  | 'MU SHOT'
  | 'MU 2 PT SHOT'
  | '2 PT'
  | 'BONUS'
  
export const PLAY_LOG_EVENT_TYPES: {[key: string]: PlayLogEventType} = {
  shot: 'shot',
  faceoff: 'faceoff',
  groundball: 'groundball',
  penalty: 'penalty',
  powerplayend: 'powerplayend',
  turnover: 'turnover',
  shotclockexpired: 'shotclockexpired',
  ballcleared: 'ballcleared',
  timeoutcalled: 'timeoutcalled',
}

export const PLAY_LOG_DESCRIPTOR_TYPES: {[key: string]: PlayLogDescriptorType} = {
  SHOT_GOOD: 'SHOT GOOD',
  TWO_PT: '2 PT',
  MU_SHOT: 'MU SHOT',
  MU_2_PT_SHOT: 'MU 2 PT SHOT',
  BONUS: 'BONUS'
}

export const PLAY_LOG_TITLE_MAP: {
  [key: string]: string | ((log: PlayLogs) => string)
} = {
  shot: (log: PlayLogs) => (log.descriptor.includes('SHOT GOOD') ? 'Goal' : 'Shot'),
  faceoff: 'Faceoff',
  turnover: 'Turnover',
  groundball: 'Groundball',
  ballcleared: 'Clear',
  shotclockexpired: 'Violation',
  timeoutcalled: 'Timeout',
  powerplayend: 'Power Play',
  penalty: 'Penalty',
}
