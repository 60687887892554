import { PLL_IMAGE_URL } from '../Config/League/league.config'
import { PlayerSortKeys } from '../Context'
import { styled } from '@mui/system'

export const defaultPlayerProfile = `${PLL_IMAGE_URL}/2024/05/Headshot_generic_nobackground.webp`

// Used to fetch leaders for player leaderboards
export const seasonLeadersStatArray = [
  'points',
  'onePointGoals',
  'twoPointGoals',
  'scoringPoints',
  'assists',
  'shots',
  'pointsPG',
  'onePointGoalsPG',
  'assistsPG',
  'shotsPG',
  'shotPct',
  'touches',
  'faceoffPct',
  'faceoffWinsPG',
  'touchesPG',
  'savesPG',
  'savePct',
  'causedTurnovers',
  'causedTurnoversPG',
  'groundBalls',
  'groundBallsPG',
]

// Used to fetch CS leaders for player leaderboards
export const csLeadersStatArray = [
  'points',
  'onePointGoals',
  'twoPointGoals',
  'assists',
  'shots',
  'pointsPG',
  'onePointGoalsPG',
  'assistsPG',
  'shotsPG',
  'shotPct',
  'touches',
  'touchesPG',
  'savesPG',
  'saves',
  'savePct',
  'causedTurnovers',
  'causedTurnoversPG',
  'groundBalls',
  'groundBallsPG',
]

// Stats Types For Player Leaderboards
export const statsTypes: PlayerSortKeys[] = [
  'points',
  'onePointGoals',
  'twoPointGoals',
  'scoringPoints',
  'assists',
  'shots',
  'shotPct',
  'touches',
  'faceoffPct',
  'savePct',
  'causedTurnovers',
  'groundBalls',
]

// Stats Types For Player Leaderboards By Avg
export const avgStatsTypes: PlayerSortKeys[] = [
  'pointsPG',
  'onePointGoalsPG',
  'assistsPG',
  'shotsPG',
  'shotPct',
  'touchesPG',
  'savesPG',
  'savePct',
  'faceoffPct',
  'faceoffWinsPG',
  'causedTurnoversPG',
  'groundBallsPG',
]

export const champSeriesStatTitles: PlayerSortKeys[] = [
  'points',
  'onePointGoals',
  'twoPointGoals',
  'assists',
  'touches',
  'shots',
  'shotPct',
  'causedTurnovers',
  'saves',
  'savePct',
  'groundBalls',
]
// Stats Types For Player Leaderboards By Avg
export const csAvgStatsTypes: PlayerSortKeys[] = [
  'pointsPG',
  'onePointGoalsPG',
  'assistsPG',
  'shotsPG',
  'shotPct',
  'touchesPG',
  'savesPG',
  'savePct',
  'causedTurnoversPG',
  'groundBallsPG',
]

export const DownloadCon = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const defenseTableHeaders = [
  { CT: 'Caused Turnovers' },
  { GB: 'Groundballs' },
  { P: 'Points' },
]

export const goalieTableHeaders = [
  { Sv: 'Saves' },
  { 'Sv%': 'Save Percentage' },
  { SA: 'Scores Against' },
  { '2ptGA': 'Two Point Goals Against' },
]
export const goalieGameTableHeaders = [
  { Sv: 'Saves' },
  { 'Sv%': 'Save Percentage' },
  { SA: 'Scores Against' },
  { '2ptGA': 'Two Point Goals Against' },
]

export const faceoffTableHeaders = [
  { FO: 'Faceoffs' },
  { 'FO%': 'Faceoff Percentage' },
  { GB: 'Groundballs' },
  { P: 'Points' },
]

export type HeaderCell = {
  id: string
  long: string
  numeric: boolean
  label: string
  minWidth: number
}

export const playerInfoFullTableHeaders: HeaderCell[] = [
  { id: 'lastName', long: 'Player', numeric: false, label: 'Player', minWidth: 200 },
  { id: 'position', long: 'Position', numeric: false, label: 'Pos', minWidth: 20 },
]
export const playerFullTableHeaders: HeaderCell[] = [
  { id: 'gamesPlayed', long: 'Games Played', numeric: true, label: 'GP', minWidth: 20 },
  {
    id: 'points',
    long: 'Points',
    numeric: true,
    label: 'P',
    minWidth: 20,
  },
  {
    id: 'scoringPoints',
    long: 'Scoring Points',
    numeric: true,
    label: 'SP',
    minWidth: 20,
  },
  {
    id: 'goals',
    long: 'Total Goals',
    numeric: true,
    label: 'TG',
    minWidth: 20,
  },
  { id: 'onePointGoals', long: '1Pt Goals', numeric: true, label: '1G', minWidth: 30 },
  { id: 'twoPointGoals', long: '2Pt Goals', numeric: true, label: '2G', minWidth: 30 },
  { id: 'assists', long: 'Assists', numeric: true, label: 'A', minWidth: 30 },
  { id: 'shots', long: 'Shots', numeric: true, label: 'Sh', minWidth: 30 },
  { id: 'shotPct', long: 'Shooting %', numeric: true, label: 'Sh%', minWidth: 30 },
  {
    id: 'shotsOnGoal',
    long: 'Shots On Goal',
    numeric: true,
    label: 'SOG',
    minWidth: 30,
  },
  {
    id: 'shotsOnGoalPct',
    long: 'Shots On Goal %',
    numeric: true,
    label: 'SOG%',
    minWidth: 30,
  },
  {
    id: 'twoPointShots',
    long: '2Pt Shots',
    numeric: true,
    label: '2PtSh',
    minWidth: 30,
  },
  {
    id: 'twoPointShotPct',
    long: '2Pt Shots %',
    numeric: true,
    label: '2PtSh%',
    minWidth: 30,
  },
  {
    id: 'twoPointShotsOnGoal',
    long: '2Pt Shots On Goal',
    numeric: true,
    label: '2SOG',
    minWidth: 30,
  },
  {
    id: 'twoPointShotsOnGoalPct',
    long: '2Pt Shots On Goal %',
    numeric: true,
    label: '2SOG%',
    minWidth: 30,
  },
  { id: 'turnovers', long: 'Turnovers', numeric: true, label: 'TO', minWidth: 30 },
  {
    id: 'causedTurnovers',
    long: 'Caused Turnovers',
    numeric: true,
    label: 'CT',
    minWidth: 30,
  },
  { id: 'groundBalls', long: 'Groundballs', numeric: true, label: 'GB', minWidth: 30 },
  { id: 'touches', long: 'Touches', numeric: true, label: 'TCH', minWidth: 30 },
  { id: 'totalPasses', long: 'Passes', numeric: true, label: 'PAS', minWidth: 30 },
  { id: 'faceoffPct', long: 'Faceoff %', numeric: true, label: 'FO%', minWidth: 30 },
  {
    id: 'faceoffsWon',
    long: 'Faceoffs Won',
    numeric: true,
    label: 'FOW',
    minWidth: 30,
  },
  {
    id: 'faceoffsLost',
    long: 'Faceoffs Lost',
    numeric: true,
    label: 'FOL',
    minWidth: 30,
  },
  { id: 'faceoffs', long: 'Faceoffs', numeric: true, label: 'FO', minWidth: 30 },
  {
    id: 'saa',
    long: 'Scores Against Average',
    numeric: true,
    label: 'SAA',
    minWidth: 30,
  },
  { id: 'saves', long: 'Saves', numeric: true, label: 'Sv', minWidth: 30 },
  { id: 'savePct', long: 'Save %', numeric: true, label: 'Sv%', minWidth: 30 },
  {
    id: 'scoresAgainst',
    long: 'Scores Against',
    numeric: true,
    label: 'SA',
    minWidth: 30,
  },
  {
    id: 'twoPointGoalsAgainst',
    long: '2Pt Goals Against',
    numeric: true,
    label: '2PtGA',
    minWidth: 30,
  },
  { id: 'numPenalties', long: 'Penalties', numeric: true, label: 'PEN', minWidth: 30 },
  {
    id: 'pim',
    long: 'Penalties (in minutes)',
    numeric: true,
    label: 'PIM',
    minWidth: 30,
  },
  {
    id: 'powerPlayGoals',
    long: 'Power Play Goals',
    numeric: true,
    label: 'PowG',
    minWidth: 30,
  },
  {
    id: 'powerPlayShots',
    long: 'Power Play Shots',
    numeric: true,
    label: 'PPSh',
    minWidth: 30,
  },
  {
    id: 'powerPlayGoalsAgainst',
    long: 'Power Play Goals Against',
    numeric: true,
    label: 'PPGA',
    minWidth: 30,
  },
  {
    id: 'shortHandedGoals',
    long: 'Short Handed Goals',
    numeric: true,
    label: 'SHG',
    minWidth: 30,
  },
  {
    id: 'shortHandedShots',
    long: 'Short Handed Shots',
    numeric: true,
    label: 'SHSh',
    minWidth: 30,
  },
  {
    id: 'unassistedGoals',
    long: 'Unassisted Goals',
    numeric: true,
    label: 'UAG',
    minWidth: 30,
  },
  {
    id: 'assistedGoals',
    long: 'Assisted Goals',
    numeric: true,
    label: 'AG',
    minWidth: 30,
  },
]

export const mapPlayerCSVHeaders = () => {
  const infoHeaders: { displayName: string; id: string }[] = [
    { displayName: 'First Name', id: 'firstName' },
    { displayName: 'Last Name', id: 'lastName' },
    { displayName: 'Position', id: 'position' },
    { displayName: 'Jersey', id: 'jerseyNum' },
    { displayName: 'Team', id: 'team' },
  ]
  const csvHeaders = playerFullTableHeaders.map((h) => {
    return {
      displayName: h.id,
      id: h.id,
    }
  })
  return [...infoHeaders, ...csvHeaders]
}
