import { EventGQL, PlayerGameLogs, PlayLogs } from '../../Api'
import {
  createFaceoffPlayLog,
  createGroundballPlayLog,
  createPenaltyPlayLog,
  createPowerPlayPlayLog,
  createShotPlayLog,
  createTurnoverPlayLog,
} from '../../Components/GameComponents/PlayLogs/components/playLogHelpers'
import { PLAY_LOG_DESCRIPTOR_TYPES, PLAY_LOG_EVENT_TYPES, PLAY_LOG_TITLE_MAP } from '../../Constants/events'

//eventType for play logs
export const logEnums = {
  shot: 'shot',
  faceoff: 'faceoff',
}

export const filterPlayLogs = (logs: PlayLogs[], filter: string) => {
  if (logs.length < 1) return logs
  let filtered = {
    all: logs,
    scores: logs.filter((pl) => pl?.descriptor && pl.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.SHOT_GOOD)),
    penalty: logs.filter((pl) => pl.eventType === PLAY_LOG_EVENT_TYPES.penalty),
    powerplayend: logs.filter(
      (pl) =>
        pl.eventType === PLAY_LOG_EVENT_TYPES.powerplayend ||
        pl.eventType === PLAY_LOG_EVENT_TYPES.penalty ||
        (pl?.descriptor && pl.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.MU_SHOT)) ||
        (pl?.descriptor && pl.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.MU_2_PT_SHOT))
    ),
  }
  if (filter in filtered) {
    return filtered[filter]
  }
  return logs.filter((pl) => pl.eventType === filter)
}

export const filterPlayLogsByPeriod = (logs: PlayLogs[]) => {
  return {
    1: logs.filter(
      (pl) => pl.period === 1 && pl?.descriptor && pl.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.SHOT_GOOD)
    ),
    2: logs.filter(
      (pl) => pl.period === 2 && pl?.descriptor && pl.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.SHOT_GOOD)
    ),
    3: logs.filter(
      (pl) => pl.period === 3 && pl?.descriptor && pl.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.SHOT_GOOD)
    ),
    4: logs.filter(
      (pl) => pl.period === 4 && pl?.descriptor && pl.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.SHOT_GOOD)
    ),
    5: logs.filter(
      (pl) => pl.period > 4 && pl?.descriptor && pl.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.SHOT_GOOD)
    ),
  }
}

export const createPlayLogDesc = (
  log: PlayLogs,
  event: EventGQL,
  showWinType: boolean = true,
  logType?: string
) => {
  if (logType) {
    log.eventType = logType.toLowerCase()
  }
  if (log?.eventType in playLogDescriptionConverter) {
    const converter = playLogDescriptionConverter[log.eventType]
    if (typeof converter === 'function') {
      return converter(log, { event, showWinType })
    }
    return converter || ''
  } else {
    return log?.descriptor
  }
}

export const playLogDescriptionConverter = {
  shot: (log: PlayLogs, params: { event: EventGQL; showWinType: boolean }) =>
    createShotPlayLog(log, params.event),
  faceoff: (log: PlayLogs, params: { event: EventGQL; showWinType: boolean }) =>
    createFaceoffPlayLog(log, params.showWinType),
  groundball: (log: PlayLogs) => createGroundballPlayLog(log),
  penalty: (log: PlayLogs, params: { event: EventGQL; showWinType: boolean }) =>
    createPenaltyPlayLog(log, params.event),
  powerplayend: (log: PlayLogs) => createPowerPlayPlayLog(log),
  turnover: (log: PlayLogs, params: { event: EventGQL; showWinType: boolean }) =>
    createTurnoverPlayLog(log, params.event),
  shotclockexpired: `Shot Clock Violation.`,
  ballcleared: `Successful clear.`,
  timeoutcalled: `Timeout called.`,
}

export const convertToPlayLogTitle = (log: PlayLogs) => {
  if (log?.eventType in PLAY_LOG_TITLE_MAP) {
    const converter = PLAY_LOG_TITLE_MAP[log.eventType]
    if (typeof converter === 'function') {
      return converter(log)
    }
    return converter || ''
  }
  return log.eventType
}

export const convertEventToGameLogs = (playerLogs: PlayerGameLogs) => {
  return playerLogs.allEvents.map((log) => {
    const opponent =
      log.awayTeam.officialId === playerLogs.currentTeam.officialId
        ? log.homeTeam.officialId
        : log.awayTeam.officialId
    return {
      officialId: playerLogs.officialId,
      teamId: playerLogs.currentTeam.officialId,
      gamesPlayed: log.playerEventStats.gamesPlayed,
      goals: log.playerEventStats.goals,
      assists: log.playerEventStats.assists,
      points: log.playerEventStats.points,
      turnovers: log.playerEventStats.turnovers,
      shots: log.playerEventStats.shots,
      shotPct: log.playerEventStats.shotPct,
      shotsOnGoal: log.playerEventStats.shotsOnGoal,
      shotsOnGoalPct: log.playerEventStats.shotsOnGoalPct,
      gamesStarted: log.playerEventStats.gamesStarted,
      onePointGoals: log.playerEventStats.onePointGoals,
      twoPointGoals: log.playerEventStats.twoPointGoals,
      saves: log.playerEventStats.saves,
      savePct: log.playerEventStats.savePct,
      goalsAgainst: log.playerEventStats.goalsAgainst,
      twoPointGoalsAgainst: log.playerEventStats.twoPointGoalsAgainst,
      eventId: log.slugname,
      scheduledStartUtc: log.startTime,
      seasonSegment: log.seasonSegment === 'regular' ? 1 : 2,
      opponent: opponent,
    }
  })
}
