export const basicTeamInfoFragment = `
  fragment BasicTeamInfoFragment on Team {
        officialId
        location
        locationCode
        fullName
        urlLogo
        teamWins
        teamLosses
        teamTies
}
  `

  export const fullTeamStatsFragment = `
  fragment FullTeamStatsFragment on TeamStatsType {
      scores
      faceoffPct
      shotPct
      twoPointShotPct
      twoPointShotsOnGoalPct
      clearPct
      ridesPct
      savePct
      shortHandedPct
      shortHandedGoalsAgainstPct
      powerPlayGoalsAgainstPct
      manDownPct
      shotsOnGoalPct
      onePointGoals
      scoresAgainst
      saa
      powerPlayPct
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      shots
      twoPointShots
      twoPointShotsOnGoal
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      clears
      clearAttempts
      rides
      rideAttempts
      saves
      offsides
      shotClockExpirations
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedShotsAgainst
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      powerPlayShotsAgainst
      timesManUp
      timesShortHanded
      shotsOnGoal
      scoresPG
      shotsPG
      totalPasses
      touches
}
`

export const fullTeamGameStatsFragment = `
  fragment FullTeamGameStatsFragment on TeamStatsType {
      assists
      causedTurnovers
      clearAttempts
      clearPct
      clears
      faceoffPct
      faceoffs
      faceoffsLost
      faceoffsWon
      gamesPlayed
      goals
      goalsAgainst
      groundBalls
      manDownPct
      numPenalties
      onePointGoals
      pim
      powerPlayPct
      powerPlayGoals
      powerPlayShots
      powerPlayGoalsAgainst
      powerPlayGoalsAgainstPct
      rideAttempts
      saa
      savePct
      saves
      scores
      scoresAgainst
      shotClockExpirations
      shotPct
      shots
      shotsOnGoal
      shotsOnGoalPct
      timesManUp
      timesShortHanded
      turnovers
      twoPointGoalsAgainst
      twoPointShots
      twoPointShotPct
      twoPointShotsOnGoal
      twoPointShotsOnGoalPct
      twoPointGoals
      touches
      totalPasses
      timeInPossesion
      timeInPossesionPct
}
`
