import { getStatAvg } from './statsProcess'
import type { PlayersStatsData, PlayerData, PlayerWithStatsData } from '../../Api'
import { TeamPlayers } from '../../Api/teams/teams.types'
import {
  AllTimePlayerInfo,
  FlattenedPlayer,
  LeaderStats,
  LeagueLeader,
  PlayerEventStats,
  PlayerTeam,
} from '../../Api/players/players.types'
import { LeaderBoundary } from './leaderConstants'
import { teamLocationCodes } from '../../Constants/TeamConstants'
import { OPERATORS, StatFilterOption } from '../../Constants/consts'
import { formatNumberOrdinal } from '../formatting'
import {
  offensiveSeasonStatsMap,
  playerSeasonHeadersPositionMap,
  statDisplayNames,
} from '../../Constants/stats'
import { playerFullTableHeaders } from '../../Constants/PlayerConstants'

export type PlayerSortKeys = keyof PlayerWithStatsData
export type PlayerStatsSortKeys = keyof PlayersStatsData
export type TeamPlayerSortKeys = keyof PlayerEventStats

/**
 * Returns player name formatted as "F. Last"
 */
export const formatPlayerName = (first?: string, last?: string) => {
  if (!first) return last || ''
  if (first.length > 2) {
    let firstInitial = first.charAt(0)
    return `${firstInitial}. ${last}`
  }
  // Name like TD Ierlan
  return `${first} ${last}`
}

export const mapPlayerStat = (stat: string): string => {
  if (stat in statDisplayNames) {
    return statDisplayNames[stat]
  }
  return stat
}

export const mapPlayerHeaders = (position: string) => {
  if (!position) return offensiveSeasonStatsMap
  //use long name object
  if (position in playerSeasonHeadersPositionMap.short) {
    return playerSeasonHeadersPositionMap.short[position]
  }
  return offensiveSeasonStatsMap
}

export const positionNameToCode = (positionName: string) => {
  const positionConvert: { [key: string]: string } = {
    Attack: 'A',
    Midfield: 'M',
    'Defensive Midfield': 'SSDM',
    'Long Stick Midfield': 'LSM',
    'Short Stick Defensive Midfield': 'SSDM',
    Defense: 'D',
    Faceoff: 'FO',
    Field: 'F',
    Goalie: 'G',
    Rookie: 'R',
  }
  return positionConvert[positionName] || ''
}

export const mapPlayerHeadersByFullPosition = (position: string) => {
  if (!position) return offensiveSeasonStatsMap
  //use long name object
  if (position in playerSeasonHeadersPositionMap.long) {
    return playerSeasonHeadersPositionMap.long[position]
  }
  return offensiveSeasonStatsMap
}

export const applyLeaderBoundaries = (
  plrs: PlayerData[],
  stat: string,
  segment: string
) => {
  const filteredLeaders = setLeaderBoundaries(plrs, stat, segment)
  const ldrs = filteredLeaders.map((row) => {
    const result = {
      ...row,
      statName: mapPlayerStat(stat),
      stat: stat,
    }
    return result
  })
  return ldrs
}

const getStatsObj = (segment: string, player: PlayerData) => {
  let statsObj =
    segment === 'post'
      ? player?.postStats || null
      : segment === 'champseries'
      ? player?.champSeries?.stats || null
      : player?.stats
      ? player.stats
      : null

  return statsObj
}

const filterByStatAndSegment = (
  list: PlayerData[],
  statsType: string,
  segment: string,
  conditionFunc: Function
) => {
  let l = list.filter((player) => {
    let pObj = getStatsObj(segment, player)
    return pObj && statsType in pObj && conditionFunc(pObj, statsType)
  })
  return l
}

export const setLeaderBoundaries = (
  list: PlayerData[],
  statsType: string,
  segment: string
) => {
  const statLimitBySegment = segment === 'regular' ? 2.5 : 2
  const conditionMap: { [key: string]: (stats: PlayersStatsData) => boolean } = {
    shotPct: (stats) =>
      getStatAvg(stats?.shots, stats?.gamesPlayed) > statLimitBySegment &&
      stats.shotPct <= LeaderBoundary.shotPct,
    shotsOnGoalPct: (stats) =>
      getStatAvg(stats?.shots, stats?.gamesPlayed) > statLimitBySegment &&
      stats.shotsOnGoalPct <= LeaderBoundary.shotsOnGoalPct,
    twoPointShotPct: (stats) =>
      getStatAvg(stats?.shots, stats?.gamesPlayed) > statLimitBySegment &&
      stats.twoPointShotPct <= LeaderBoundary.twoPointShotPct,
    twoPointShotsOnGoalPct: (stats) =>
      getStatAvg(stats?.shots, stats?.gamesPlayed) > statLimitBySegment &&
      stats.twoPointShotsOnGoalPct <= LeaderBoundary.twoPointShotsOnGoalPct,
    savePct: (stats) => stats.savePct <= LeaderBoundary.savePct,
    faceoffPct: (stats) => stats.faceoffPct <= LeaderBoundary.faceoffPct,
  }

  return filterByStatAndSegment(
    list,
    statsType,
    segment,
    conditionMap[statsType] || ((stats: PlayersStatsData) => stats[statsType] > 0)
  )
}

export const filterPlayersWithRegisteredStat = (
  players: PlayerData[],
  statType: string
) => {
  return players.filter(
    ({ stats, team }) =>
      (statType === 'points' && (stats?.shots ?? 0) > 0 && (stats?.assists ?? 0) > 0) ||
      (statType === 'faceoffsWon' && (stats?.faceoffs ?? 0) > 0) ||
      (statType === 'saves' &&
        team?.position === 'G' &&
        ((stats?.saves ?? 0) > 0 ||
          (stats?.goalsAgainst ?? 0) > 0 ||
          (stats?.groundBalls ?? 0) > 0))
  )
}

export const flattenAllPlayerObjs = (players: PlayerData[], segment: string) => {
  let flatList: FlattenedPlayer[] = []
  players.map((p) => {
    if (segment === 'champseries' && !p.champSeries) return
    let statsToFlatten =
      segment === 'post'
        ? p.postStats
        : segment === 'champseries' && p?.champSeries?.stats
        ? p.champSeries.stats
        : p.stats
    const flattened: FlattenedPlayer = {
      officialId: p.officialId,
      slug: p.slug,
      firstName: p.firstName,
      lastName: p.lastName,
      lastNameSuffix: p.lastNameSuffix,
      profileUrl: p.profileUrl,
      jerseyNum: p.jerseyNum,
      position: p.position,
      team: p?.team,
      ...statsToFlatten,
    }
    flatList.push(flattened)
    return
  })
  return flatList
}

export const filterPlayerTable = (
  list: FlattenedPlayer[],
  statsType: PlayerSortKeys,
  segment: string
) => {
  switch (statsType) {
    case 'savePct':
      return filterByStat(list, statsType, 0.85)
    case 'saa':
      return filterByStat(list, statsType, 0, true)
    case 'faceoffPct':
      return filterByStat(list, statsType, 0.9).filter(
        (player) => player.faceoffs > player.gamesPlayed * 2
      )
    case 'shotPct':
    case 'shotsOnGoalPct':
    case 'twoPointShotPct':
    case 'twoPointShotsOnGoalPct':
      return filterByStat(list, statsType, 1)
    case 'scoresAgainst':
    case 'twoPointGoalsAgainst':
      return filterByStat(list, statsType, 2, true)
    default:
      return list
  }
}

const filterByStat = (
  list: FlattenedPlayer[],
  statType: string,
  value: number,
  requiresSaves = false
) =>
  list.filter((player) => {
    const val = player[statType]
    const savesPerGame =
      player?.saves && player.gamesPlayed ? player.saves / player.gamesPlayed : 0
    return (val < value && !requiresSaves) || savesPerGame > value
  })

export const filterAdvancedPlayerTable = (list: any[], filters: StatFilterOption[]) => {
  const gamesPlayedFilter = {
    key: 'gamesPlayed',
    operator: OPERATORS.gt,
    value: 0,
  }
  if (!filters.some((filter) => filter.key === gamesPlayedFilter.key)) {
    filters.push(gamesPlayedFilter)
  }

  return list.filter((player) =>
    filters.every((filter) => {
      const value = player[filter.key]
      switch (filter.operator) {
        case OPERATORS.eq:
          return value === filter.value
        case OPERATORS.gte:
          return value >= filter.value
        case OPERATORS.gt:
          return value > filter.value
        case OPERATORS.lt:
          return value < filter.value
        case OPERATORS.lte:
          return value <= filter.value
        default:
          return true
      }
    })
  )
}

export const sortPlayerTable = (
  list: any[],
  statsType: PlayerSortKeys,
  segment: string = 'regular'
) => {
  switch (statsType) {
    case 'savePct':
      const sl = list.filter((player) => player[statsType] < 0.85)
      if (sl.length < 1) return list
      return sortPlayerFunc(sl, 'savePct', segment, -1)
    case 'faceoffPct':
      const fl = list.filter(
        (player) => player[statsType] < 0.9 && player.faceoffs > player.gamesPlayed * 2
      )
      if (fl.length < 1) return list
      return sortPlayerFunc(fl, 'faceoffPct', segment, -1)
    case 'shotPct':
      const shl = list.filter((player) => player[statsType] < 1)
      if (shl.length < 1) return list
      return sortPlayerFunc(shl, 'shotPct', segment, -1)
    case 'position':
      return sortPlayerByPosition(list)
    default:
      return sortPlayerFunc(list, statsType, segment, -1)
  }
}

export const sortPlayerByPosition = (list: any[]) => {
  return list.sort((a, b) => {
    if (!a?.team || !b?.team) return a > b
    return a.team.position.localCompare(b.team.position)
  })
}

export const sortPlayerFunc = (
  list: PlayerData[],
  sortBy: PlayerSortKeys,
  seasonSegment: string,
  ascDesc: -1 | 1
) => {
  return list.sort((a, b) => {
    let aStatsToSort =
      seasonSegment === 'post'
        ? a.postStats
        : seasonSegment === 'champseries'
        ? a.champSeries?.stats
        : a.stats
    let bStatsToSort =
      seasonSegment === 'post'
        ? b.postStats
        : seasonSegment === 'champseries'
        ? b.champSeries?.stats
        : b.stats
    const A = aStatsToSort ? aStatsToSort[sortBy] : 0
    const B = bStatsToSort ? bStatsToSort[sortBy] : 0
    const numA = typeof B === 'number' ? B : 0
    const numB = typeof A === 'number' ? A : 0
    if (ascDesc < 0) {
      return numA - numB
    } else {
      return numB - numA
    }
  })
}

export const sortTeamPlayerFunc = (
  list: TeamPlayers[],
  sortBy: TeamPlayerSortKeys,
  ascDesc: -1 | 1
) => {
  return list.sort((a, b) => {
    const A = a.stats ? a.stats[sortBy] : 0
    const B = b.stats ? b.stats[sortBy] : 0
    const numA = typeof B === 'number' ? B : 0
    const numB = typeof A === 'number' ? A : 0
    if (ascDesc < 0) {
      return numA - numB
    } else {
      return numB - numA
    }
  })
}

export const extractPlayerTeam = (
  players: PlayerData[],
  year: number,
  teamCode?: string
) => {
  return players.map((plr) => {
    let tm: PlayerTeam | null = null
    tm = getLeaderTeam(year, plr, teamCode)
    plr.team = tm
    return plr
  })
}

export const getPlayerTeam = (year: number, player: PlayerData | TeamPlayers) => {
  if (!player?.allTeams || player?.allTeams.length < 1) return null
  const team = player.allTeams.find((tm) => tm.year === year)
  // return latest team if no team
  if (!team) return player.allTeams[0]
  return team
}

export const getPlayerTeamCode = (
  year: number,
  player: PlayerData,
  segment?: string
) => {
  if (segment === 'champseries' && player?.champSeries) {
    let team = getLeaderChampSeriesTeam(player, year)
    return team?.locationCode || team?.officialId
  }
  if (!player?.allTeams || player?.allTeams.length < 1) return null
  const team = player.allTeams.find((tm) => tm.year === year)
  // return latest team if no team
  if (!team) return player.allTeams[0].locationCode || player.allTeams[0].officialId
  return team.locationCode || team.officialId
}

export const getPlayerTeamCodeByTeamId = (year: number, teamId: string) => {
  return year >= 2024 ? teamLocationCodes[teamId] : teamId
}

export const getLeaderTeam = (year: number, player: PlayerData, teamCode?: string) => {
  if (!player?.allTeams) return null
  let team = player.allTeams.find((tm) => tm.year === year)
  if (!team) return null
  if (teamCode) {
    //To avoid fetching Champ Series data
    team.officialId = teamCode
  }
  return team
}

export const getLeaderChampSeriesTeam = (player: PlayerData, year: number) => {
  const csObj = player?.champSeries
  if (csObj && csObj?.team) {
    csObj.officialId = csObj.team.officialId
    csObj.jerseyNum = player?.jerseyNum
    csObj.year = year
    if (csObj.team.locationCode) {
      csObj.locationCode = csObj.team.locationCode
    }
    return csObj
  }
  return null
}

export const mapPlayersToCSV = (players: any[], segment: string) => {
  const cleaned = players.map((plr) => {
    let player = {
      firstName: plr.firstName,
      lastName: plr.lastName,
      jerseyNum: plr?.team?.jerseyNum,
      position: plr?.team?.position,
      team: plr?.team?.fullName,
    }
    const stats =
      segment === 'champseries' && plr.champSeries?.stats
        ? plr?.champSeries?.stats
        : segment === 'post'
        ? plr?.postStats
        : plr?.stats
    if (!stats) return player

    let statsObj = {}

    playerFullTableHeaders.map((h) => {
      if (!(h.id in stats)) return
      statsObj[h.id] = stats[h.id] || 0
      return
    })
    return { ...player, ...statsObj }
  })
  return cleaned
}

/**
 *
 * Getting player's rank in a stat category
 */

export const generateLeadersWithRanks = (plrs: LeagueLeader[]): LeagueLeader[] => {
  let rank = 1
  let actualRank = 1 // Handles skipping ranks when tied

  return plrs.map((player, index) => {
    const isTiedWithPrev =
      index > 0 && plrs[index].statValue === plrs[index - 1].statValue
    const isTiedWithNext =
      index < plrs.length - 1 && plrs[index].statValue === plrs[index + 1].statValue

    const rankLabel =
      isTiedWithPrev || isTiedWithNext
        ? `T-${formatNumberOrdinal(rank)}`
        : `${formatNumberOrdinal(rank)}`

    player.rank = rankLabel
    actualRank++

    if (!isTiedWithNext) {
      rank = actualRank
    }
    return player
  })
}

export const generateCareerLeadersWithRanks = (
  plrs: AllTimePlayerInfo[],
  stat: keyof LeaderStats,
  useOrdinals: boolean = false
): AllTimePlayerInfo[] => {
  let rank = 1
  let actualRank = 1 // Handles skipping ranks when tied

  return plrs.map((player, index) => {
    const isTiedWithPrev = index > 0 && plrs[index][stat] === plrs[index - 1][stat]
    const isTiedWithNext =
      index < plrs.length - 1 && plrs[index][stat] === plrs[index + 1][stat]

    const rankNumber = useOrdinals ? formatNumberOrdinal(rank) : rank

    const rankLabel =
      isTiedWithPrev || isTiedWithNext ? `T-${rankNumber}` : `${rankNumber}`

    player.rank = rankLabel
    actualRank++

    if (!isTiedWithNext) {
      rank = actualRank
    }
    return player
  })
}
