import {
  fullPlayerGameStatsFragment,
  minPlayerInfoFragment,
  playerAllTeamsFragment,
} from '../players/player.fragments'
import {
  basicTeamInfoFragment,
  fullTeamGameStatsFragment,
  fullTeamStatsFragment,
} from '../teams/team.fragments'
import { winProbabilitiesFragment } from './events.fragments'

export const seasonEventsQuery = `
query($season: Int!, $includeCS: Boolean!) {
  seasonEvents(season: $season, includeCS: $includeCS) {
    id
    slugname
    externalId
    year
    startTime
    week
    venue
    description
    location
    broadcaster
    eventStatus
    period
    homeScore
    visitorScore
    urlTicket
    urlPreview
    urlStreaming
    gameStatus
    seasonSegment
    league
    year
    awayTeam {
      ...basicTeamInfoFragment
      champSeries (year: $season) @include(if: $includeCS) {
        teamWins
        teamLosses
        teamTies
      }
      }
    homeTeam {
      ...basicTeamInfoFragment
      champSeries (year: $season) @include(if: $includeCS) {
        teamWins
        teamLosses
        teamTies
      }
    }
  }
}
  ${basicTeamInfoFragment}
`

export const eventInProgressBySlug = `
query($slug: ID) {
  event(slug:$slug) {
    eventStatus
    visitorScore
    homeScore
    period
    clockTenths
    clockSeconds
    clockMinutes
  }
}
`

export const eventInfoAndStatsById = `
query($id: ID) {
  event(slug: $id) {
    id
    slugname
    externalId
    year
    startTime
    week
    venue
    description
    location
    gameNumber
    broadcaster
    eventStatus
    period
    clockMinutes
    clockSeconds
    homeScore
    homePeriodScores
    visitorScore
    visitorPeriodScores
    urlTicket
    league
    urlPreview
    seasonSegment
    urlStreaming
    gameStatus
    awayTeam {
      ...BasicTeamInfoFragment
      stats(segment: event) {
        ...FullTeamGameStatsFragment
        }
      players: playersGameStats {
        ...MinPlayerInfoFragment
          allTeams {
              ...PlayerAllTeamsFragment
            }
            stats(segment: event) {
              ...FullPlayerGameStatsFragment
            }
        }
      }
    homeTeam {
      ...BasicTeamInfoFragment
      stats(segment: event) {
        ...FullTeamGameStatsFragment
      }
      players: playersGameStats {
        ...MinPlayerInfoFragment
        allTeams {
            ...PlayerAllTeamsFragment
          }
          stats(segment: event) {
            ...FullPlayerGameStatsFragment
          }
        }
    }
    faceoffHeadToHead {
      player
      officialId
      opponentRecords{
        player
        officialId
        record{
          wins
          losses
        }
      }
    }
    liveWinProbablities {
      ...WinProbabilitiesFragment
    }
  }
  }
  ${basicTeamInfoFragment}
  ${fullTeamGameStatsFragment}
  ${minPlayerInfoFragment}
  ${fullPlayerGameStatsFragment}
  ${playerAllTeamsFragment}
  ${winProbabilitiesFragment}
`

export const liveEventInfoAndStats = `
query($id: ID) {
  event(slug: $id) {
    id
    slugname
    externalId
    year
    startTime
    week
    venue
    description
    location
    gameNumber
    broadcaster
    eventStatus
    period
    clockMinutes
    clockSeconds
    homeScore
    homePeriodScores
    visitorScore
    visitorPeriodScores
    urlTicket
    league
    urlPreview
    seasonSegment
    urlStreaming
    gameStatus
    awayTeam {
      ...BasicTeamInfoFragment
      stats(segment: event) {
        ...FullTeamGameStatsFragment
        }
      players: playersGameStats {
        ...MinPlayerInfoFragment
          allTeams {
              ...PlayerAllTeamsFragment
            }
            stats(segment: event) {
              ...FullPlayerGameStatsFragment
            }
        }
      }
    homeTeam {
      ...BasicTeamInfoFragment
      stats(segment: event) {
        ...FullTeamGameStatsFragment
      }
      players: playersGameStats {
        ...MinPlayerInfoFragment
        allTeams {
            ...PlayerAllTeamsFragment
          }
          stats(segment: event) {
            ...FullPlayerGameStatsFragment
          }
        }
    }
    faceoffHeadToHead {
      player
      officialId
      opponentRecords{
        player
        officialId
        record{
          wins
          losses
        }
      }
    }
    liveWinProbablities {
      ...WinProbabilitiesFragment
      }
    }
  }
  ${basicTeamInfoFragment}
  ${fullTeamStatsFragment}
  ${minPlayerInfoFragment}
  ${playerAllTeamsFragment}
  ${fullPlayerGameStatsFragment}
  ${winProbabilitiesFragment}
`

export const broadcastLiveEventInfoAndStats = `
query($id: ID) {
  event(slug: $id) {
    id
    slugname
    externalId
    year
    startTime
    week
    venue
    description
    location
    gameNumber
    broadcaster
    eventStatus
    period
    clockMinutes
    clockSeconds
    clockTenths
    homeScore
    homePeriodScores
    visitorScore
    visitorPeriodScores
    urlTicket
    league
    urlPreview
    seasonSegment
    urlStreaming
    addToCalendarId
    gameStatus
    awayTeam {
      ...BasicTeamInfoFragment
      stats(segment: event) {
        ...FullTeamGameStatsFragment
        }
      players: playersGameStats {
        ...MinPlayerInfoFragment
          allTeams {
              ...PlayerAllTeamsFragment
            }
            stats(segment: event) {
              ...FullPlayerGameStatsFragment
            }
        }
      }
    homeTeam {
      ...BasicTeamInfoFragment
      stats(segment: event) {
        ...FullTeamGameStatsFragment
      }
      players: playersGameStats {
        ...MinPlayerInfoFragment
        allTeams {
            ...PlayerAllTeamsFragment
          }
          stats(segment: event) {
            ...FullPlayerGameStatsFragment
          }
        }
    }
    faceoffHeadToHead {
      player
      officialId
      opponentRecords{
        player
        officialId
        record{
          wins
          losses
        }
      }
    }
  }
}
  ${basicTeamInfoFragment}
  ${fullTeamStatsFragment}
  ${minPlayerInfoFragment}
  ${playerAllTeamsFragment}
  ${fullPlayerGameStatsFragment}
`

export const playLogsByEvent = `
query($eventId: String!) {
  playLogs(eventId: $eventId){
    eventType
    period
    minutes
    seconds
    teamId
    descriptor
    markerId
    faceoffWinnerId
    faceoffWinnerId
    faceoffWinnerName
    faceoffLoserId,
    faceoffLoserName
    gbPlayerId
    gbPlayerName
    commitedTurnoverId
    commitedTurnoverName
    causedTurnoverId
    causedTurnoverName
    shooterId
    shooterName
    goalieId
    goalieName
    offenseGoalieId
    offenseGoalieName
    commitedPenaltyId
    commitedPenaltyName
    shotAssistId
    shotAssistName
    goalMarkerId
    penaltyLength
    penaltyDescription
    homeScore
    visitorScore
    secondsPassed
  }
}`
