import { PlayerData } from "../../Api"
import { Season } from "../../Context/SeasonContext/SeasonContext"
import { sortPlayerFunc } from "./playerHelpers"

export type PositionSortItem = {
    mainSort: string,
    backupSort: string,
    thirdSort: string
}

export const positionStatSortList = {
    offense: {
        mainSort: 'points',
        backupSort: 'onePointGoals',
        thirdSort: 'assists'
    },
    defense: {
        mainSort: 'causedTurnovers',
        backupSort: 'groundBalls',
        thirdSort: 'points'
    },
    faceoff: {
        mainSort: 'faceoffPct',
        backupSort: 'groundBalls',
        thirdSort: 'points'
    },
    goalie: {
        mainSort: 'saves',
        backupSort: 'savePct',
        thirdSort: 'groundBalls'
    },
    rookie: {
        mainSort: 'points',
        backupSort: 'onePointGoals',
        thirdSort: 'assists'
    },
    field: {
        mainSort: 'points',
        backupSort: 'onePointGoals',
        thirdSort: 'assists'
    }
}

export const groupStatSortList = {
    offensive: {
        mainSort: 'points',
        backupSort: 'onePointGoals',
        thirdSort: 'assists'
    },
    defensive: {
        mainSort: 'causedTurnovers',
        backupSort: 'groundBalls',
        thirdSort: 'points'
    },
    faceoff: {
        mainSort: 'faceoffsWon',
        backupSort: 'faceoffPct',
        thirdSort: 'groundBalls'
    },
    goalie: {
        mainSort: 'saves',
        backupSort: 'savePct',
        thirdSort: 'groundBalls'
    }
}




  export const sortByPosition = (plrs: PlayerData[], position: string, stat = 'points', season: Season) => {
    let filteredPlayers: PlayerData[] = []
    let players = [...plrs]
    if (season.year === 2019 ||season.year === 2020) {
        if(position === 'Rookie' || position === 'R'){
            filteredPlayers = players.filter(
                (player) => (player.expFromYear === season.year && player?.team?.league && player.team.league !== 'MLL'))
        } else {
            filteredPlayers = players.filter(
                (player) => (player?.team?.position && player.team.position === position)
                )
        }
    } else {
        if(position === 'Rookie' || position === 'R'){
            filteredPlayers = players.filter(
                (player) => (player.expFromYear === season.year))
        } else if (season.seasonType === 'champseries') {
            filteredPlayers = players.filter(
                (player) => (player?.champSeries?.position && player.champSeries.position === position)
                )
        } else {
            filteredPlayers = players.filter(
                (player) => (player?.team?.position && player.team.position === position)
                )
        }
    }
    return sortPlayerFunc(filteredPlayers, stat as keyof PlayerData, season.seasonType, -1)
  }

  export const sortTeamRosterByPosition = (players: PlayerData[], position: string, stat = 'points', season: Season) => {
    let filteredPlayers: PlayerData[] = []
        if(position === 'Rookie' || position === 'R'){
            filteredPlayers = players.filter(
                (player) => (player.expFromYear === season.year))
        } else if (season.seasonType === 'champseries') {
            filteredPlayers = players.filter(
                (player) => (player?.champSeries?.position && player.champSeries.position === position)
                )
        } else {
            filteredPlayers = players.filter(
                (player) => (player?.position && player.position === position)
                )
        }
    return sortPlayerFunc(filteredPlayers, stat as keyof PlayerData, season.seasonType, -1)
  }