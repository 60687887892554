import { EventGQL, PlayLogs, Team } from '../../../../Api'
import { PLAY_LOG_DESCRIPTOR_TYPES, PLAY_LOG_EVENT_TYPES } from '../../../../Constants/events'
import { formatPlayerName } from '../../../../Utils'

/**
 * Returns opponent official id
 */
export const getOpponentId = (awayTeam: Team, homeTeam: Team, teamId: string) => {
  return awayTeam?.officialId === teamId
    ? homeTeam?.locationCode ?? homeTeam?.officialId
    : awayTeam?.locationCode ?? awayTeam?.officialId
}

/**
 * Returns play log description for a shot OR goal
 */
export const createShotPlayLog = (log: PlayLogs, event: EventGQL) => {
    // MISSED SHOT
    if (!log?.descriptor && log.eventType === PLAY_LOG_EVENT_TYPES.shot){
    return `Missed shot by ${log.shooterName}. ${
      log.offenseGoalieId
        ? `Save by ${getOpponentId(
            event.awayTeam,
            event.homeTeam,
            log.teamId!
          )} ${formatLogPlayerName(log.offenseGoalieName)}.`
        : ''
    }`
  }

  //Return if no descriptor
  if (!log?.descriptor) return ''


  if (log.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.SHOT_GOOD)) {
    //POWER PLAY 2PT GOALS
    if (log.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.MU_2_PT)) {
      // 2 PT POWERPLAY GOAL
      return `${determineBonusText(log)}PP 2PT G by ${formatLogPlayerName(log.shooterName)}. ${
        log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
      }`
    }

    //POWER PLAY 1PT GOALS
    if (log.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.MU_SHOT_GOOD)) {
      // POWERPLAY GOAL
      return `${determineBonusText(log)}PP Goal by ${formatLogPlayerName(log.shooterName)}. ${
        log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
      }`
    }

    // 2PT GOALS
    if (log.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.TWO_PT)) {
      return `${determineBonusText(log)}2PT G by ${
        log.shooterName
      }. ${
        log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
      }`
    }

    //1PT GOAL
    return `${determineBonusText(log)}GOAL by ${
      log.shooterName
    }. ${log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''}`
  }

return ''
}

/**
 * Handles one year that All-Star had bonus ball
 */
const determineBonusText = (log: PlayLogs) => {
  return log.descriptor.includes(PLAY_LOG_DESCRIPTOR_TYPES.BONUS) ? `[2X BONUS] ` : ''
}

/**
 * Returns play log description for a faceoff
 */
export const createFaceoffPlayLog = (log: PlayLogs, showWinType: boolean = true) => {
  return `Faceoff win ${formatLogPlayerName(
    log.faceoffWinnerName
  )} (vs. ${formatLogPlayerName(log?.faceoffLoserName)}).${
    showWinType ? ` GB ${log.teamId} ${formatLogPlayerName(log.gbPlayerName)}.` : ''
  }`
}

/**
 * Returns play log description for a groundball
 */
export const createGroundballPlayLog = (log: PlayLogs) => {
  return `Groundball picked up by ${formatLogPlayerName(log.gbPlayerName)}.`
}

/**
 * Returns play log description for a turnover
 */
export const createTurnoverPlayLog = (log: PlayLogs, event: EventGQL) => {
  return `Turnover by ${log.teamId} ${formatLogPlayerName(log.commitedTurnoverName)}. ${
    log.causedTurnoverId
      ? `Caused turnover by ${getOpponentId(
          event.awayTeam,
          event.homeTeam,
          log.teamId!
        )} ${formatLogPlayerName(log.causedTurnoverName)}.`
      : ''
  }`
}

/**
 * Returns play log description for a turnover
 */
export const createPenaltyPlayLog = (log: PlayLogs, event: EventGQL) => {
  return `${log.teamId} ${log.commitedPenaltyName} ${convertPenTime(
    log.penaltyLength
  )} penalty for ${log.penaltyDescription}. ${getOpponentId(
    event.awayTeam,
    event.homeTeam,
    log.teamId!
  )} on the Power Play.`
}

/**
 * Returns play log description for a turnover
 */
export const createPowerPlayPlayLog = (log: PlayLogs) => {
  return `End of ${log.teamId} Power Play.`
}

/**
 * Returns play log penalty time in minutes
 */
export const convertPenTime = (time: string) => {
  if (time === '60') return '1 min'
  if (time === '90') return '1.5 min'
  return '30 sec'
}

/**
 * Converts name to first initial, full name ie J. Byrne
 */
const formatLogPlayerName = (name?: string) => {
  if (!name) return ''
  return formatPlayerName(name.split(' ')[0], name.split(' ')[1])
}
