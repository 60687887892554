import { ConvertStatValue } from '../formatting'
import type { PlayerData, PlayersStatsData, Positions } from '../../Api'
import { Season } from '../../Context/SeasonContext/SeasonContext'
import {
  defensiveStatFields,
  faceoffStatFields,
  fantasyTableHeaders,
  goaltendingStatFields,
  PackedMap,
  scoringStatFields,
  shootingStatFields,
  specialtyStatFields,
  StatMapType,
  StatTypes,
} from '../../Constants/stats'
import { FlattenedPlayer } from '../../Api/players/players.types'

export type StatListHeader =
  | 'ByPlayerPosition'
  | 'ByTeamPlayers'
  | 'GamePlayerGroups'
  | 'ByTeamGroups'
  | 'ChampSeriesByPlayerPosition'

// List of all potential stats and the corresponding fields
// [short name, long name, field name]
export const statFields: PackedMap = {
  GP: ['GP', 'Games Played', 'gamesPlayed'],
  P: ['P', 'Points', 'points'],
  ...scoringStatFields,
  ...shootingStatFields,
  ...defensiveStatFields,
  ...faceoffStatFields,
  'FO': ['FO', 'Faceoffs', 'foRecord'],
  ...goaltendingStatFields,
  '2PtGA': ['2Pt GA', '2Pt Goals Against', 'twoPointGoalsAgainst'],
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  ...specialtyStatFields,
  TCH: ['TCH', 'Touches', 'touches'],
  PAS: ['PAS', 'Total Passes', 'totalPasses'],
  BLK: ['BLK', 'Blocks', 'blocks'],
  UG: ['USh', 'Unassisted Goals', 'unnassistedGoals'],
  AG: ['AG', 'Assisted Goals', 'assistedGoals'],
}

type StatListType = {
  career: string[]
  season: string[]
  game: string[]
  fav: string[]
}

// FOR POSTIONS: M and A and F
const offense: StatListType = {
  career: ['GP', 'P', '1G', '2G', 'A', 'Sh%', 'GB', 'PowG', 'TO'],
  season: [
    'GP',
    'P',
    '1G',
    '2G',
    'A',
    'Sh',
    'Sh%',
    '2PtSh',
    '2PtSh%',
    'SOG',
    'SOG%',
    'GB',
    'TO',
    'PowG',
    'Pen',
    'PIM',
  ],
  game: ['P', '1G', '2G', 'A', 'Sh', 'Sh%', 'GB', 'TO', 'TCH', 'PAS'],
  fav: ['P', '1G', '2G', 'A'],
}

/// SSDM, LSM and D
const defense: StatListType = {
  career: ['GP', 'CT', 'GB', 'P', '1G', '2G', 'A', 'PIM'],
  season: [
    'GP',
    'CT',
    'GB',
    'P',
    '1G',
    '2G',
    'A',
    'Sh',
    'Sh%',
    '2PtSh',
    '2PtSh%',
    'TO',
    'Pen',
    'PIM',
  ],
  game: ['CT', 'GB', 'P', 'Pen', 'Sh', 'TCH'],
  fav: ['CT', 'GB', 'P'],
}

// FOR POSTIONS: FO
const faceoff: StatListType = {
  career: ['GP', 'FO', 'FO%', 'P', '1G', '2G', 'A', 'GB'],
  season: [
    'GP',
    'FO',
    'FO%',
    'P',
    '1G',
    '2G',
    'A',
    'GB',
    'Sh',
    'Sh%',
    '2PtSh',
    'TO',
    'Pen',
    'PIM',
  ],
  game: ['FO', 'FO%', 'P', 'A', 'GB', 'Sh', 'TCH'],
  fav: ['FO%', 'P', 'GB'],
}

// FOR POSTIONS: G
const goalie: StatListType = {
  career: ['GP', 'Sv%', 'Sv', 'SAA', '2PtGA', 'P', '1ptG', '2ptG', 'A', 'GB'],
  season: ['GP', 'Sv%', 'Sv', 'SAA', 'SA', '2PtGA', 'P', 'CT', 'GB', 'TO', 'PIM'],
  game: ['SA', 'Sv%', 'Sv'],
  fav: ['Sv%', 'Sv'],
}

// Stat mapping
// [short name, long name, field name]
export const getFantasyStatsMap = (): PackedMap => {
  return {
    GP: ['GP', 'Games Played', 'gamesPlayed'],
    '1G': ['1G', '1Pt Goals', 'onePointGoals'],
    '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
    A: ['A', 'Assists', 'assists'],
    TO: ['TO', 'Turnovers', 'turnovers'],
    GB: ['GB', 'Groundballs', 'groundBalls'],
    CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
    FOW: ['FOW', 'Faceoffs Won', 'faceoffsWon'],
    FOL: ['FOL', 'Faceoffs Lost', 'faceoffsLost'],
    Sv: ['Sv', 'Saves', 'saves'],
    GA: ['GA', 'Goals Against', 'goalsAgainst'],
    '2PtGA': ['2PtGA', '2Pt Goals Against', 'twoPointGoalsAgainst'],
    ...fantasyTableHeaders,
  }
}

/**
 * Header lists by position key
 */
export const positionStatHeadersLists: { [key: string]: StatListType } = {
  A: offense,
  M: offense,
  SSDM: defense,
  LSM: defense,
  D: defense,
  FO: faceoff,
  G: goalie,
  F: offense, //Field for Sixes rules
}

/**
 * Header lists by stat group key
 */
export const positionStatGroupHeaders: { [key: string]: StatListType } = {
  offensive: offense,
  defensive: defense,
  faceoff: faceoff,
  goalie: goalie,
}

/**
 * Get stats mapping for player position
 */
function getMapForPosition(position: Positions): StatListType {
  if (!position) return offense
  const positionUpper = position.toUpperCase()

  if (positionUpper in positionStatHeadersLists)
    return positionStatHeadersLists[positionUpper]
  return offense
}

/**
 * Get stats mapping for player position
 */
export const getMapForStatGroup = (group: string): StatListType => {
  if (!group) return offense
  const groupLower = group.toLowerCase()
  if (groupLower in positionStatGroupHeaders) return positionStatGroupHeaders[groupLower]
  return offense
}

export function getStatList(position: Positions, statType: StatTypes): StatMapType[] {
  const maps = getMapForPosition(position)[statType]
  const result: StatMapType[] = []
  maps.map((key) => {
    const row = statFields[key]
    if (row) {
      result.push({
        name: row[0],
        longName: row[1],
        field: row[2],
      })
    }
  })
  return result
}

/**
 * Returning list of stats to use, used in getting player leaders or game breakdowns
 */
export const getStatsToUseList = (headerType: StatListHeader): string[] => {
  switch (headerType) {
    case 'ByPlayerPosition':
      return [
        'Attack',
        'Midfield',
        'Long Stick Midfield',
        'Short Stick Defensive Midfield',
        'Defense',
        'Faceoff',
        'Goalie',
        'Rookie',
      ]
    case 'ChampSeriesByPlayerPosition':
      return ['Field', 'Goalie']
    case 'GamePlayerGroups':
      return ['Offensive', 'Defensive', 'Faceoff', 'Goalie']

    case 'ByTeamGroups':
      return [
        'Offensive',
        'Defensive',
        'Faceoff',
        'Goalie',
        'Power Play',
        'Penalty Kill',
      ]
    default:
      return []
  }
}

export const renderPlayerStat = (
  plr: PlayerData,
  v: [string, string, string],
  seasonSelect: Season
) => {
  if (seasonSelect.seasonType === 'champseries') {
    if (!plr.champSeries?.stats || !(v[2] in plr.champSeries?.stats)) return ''
    let stat = plr.champSeries?.stats
      ? plr.champSeries?.stats[v[2] as keyof PlayersStatsData]
      : 0
    return ConvertStatValue(stat, v[2])
  } else if (seasonSelect.seasonType === 'post') {
    if (!plr.postStats || !(v[2] in plr.postStats)) return ''
    let stat = plr.postStats[v[2] as keyof PlayersStatsData] || 0
    return ConvertStatValue(stat, v[2])
  } else {
    if (v[0] === 'FPTS/G' || v[0] === 'FPTS') {
      //Convert fantasy stats
      if (!plr?.fantasy) return ''
      return ConvertStatValue(plr.fantasy[v[2]], v[2])
    }
    if (!plr.stats || !(v[2] in plr.stats)) return ''
    let stat = plr.stats[v[2] as keyof PlayersStatsData] || 0
    return ConvertStatValue(stat, v[2])
  }
}

export const renderFlatPlayerStat = (flattenedPlayer: FlattenedPlayer, statField: string) => {
  if (!(statField in flattenedPlayer)) return '-'
  let stat = flattenedPlayer[statField] || 0
  return ConvertStatValue(stat, statField)
}

export const renderPlayerWeekFantasyPoints = (plr: PlayerData, week: number) => {
  if (!plr.fantasy?.seasonWeeklyPts || plr.fantasy.seasonWeeklyPts.length < 1) return ''
  let stat = plr.fantasy?.seasonWeeklyPts.find((x) => x.week === week)?.totalPts || 0
  return stat
}

export const getStatAvg = (stat: number, gamesPlayed?: number | null) => {
  if (!gamesPlayed || gamesPlayed === 0) return 0
  return stat / gamesPlayed
}

