//Years to have flexible control over different league info
export const PLL_FIRST_YEAR = 2019
export const YEAR_MIN = 2001
export const YEAR_MAX = 2024
export const CURRENT_YEAR = 2024
export const EVENTS_YEAR = 2024
export const STATS_YEAR = 2024

//Controls if we show Champ Series specific components
export const isChampSeriesLive = true
//Ability to manual set without knowing events - used in Standings context
export const hasChampSeriesStarted = true
export const champSeriesStart = 1707870510 // Feb 14, 2024
export const champSeriesEnd = 1716190516 // 2 weeks after champ series ends

export const MAIN_PLL_URL = 'premierlacrosseleague.com'
export const MAIN_PLL_HOME = `https://${MAIN_PLL_URL}`
export const PLL_IMAGE_URL = `https://${MAIN_PLL_URL}/wp-content/uploads`
export const TEAMS_STATS_URL = `https://stats.${MAIN_PLL_URL}/teams`
export const Team_LOGO_URL = `https://img.${MAIN_PLL_URL}/Teams`
export const GRAPHQL_ROOT_URL = process.env.REACT_APP_GRAPH_ROOT_URL || ''
export const GRAPHQL_ACCESS_KEY = process.env.REACT_APP_GRAPHQL_AUTH_KEY || ''
export const API_ROOT_WP = `https://${MAIN_PLL_URL}/wp-json/wp`
export const BROADCAST_IMG = `https://img.${MAIN_PLL_URL}/Logos`
export const REVERSE_BROADCAST_IMG = `https://img.${MAIN_PLL_URL}/Logos`
export const CNBC_IMG = `https://${MAIN_PLL_URL}/wp-content/uploads/2021/07`
export const APP_LINK_BASE_URL = 'https://pllmain.page.link'

export const PLL_LINKS = {
  login: `https://${MAIN_PLL_URL}/login`,
  shop: `https://shop.${MAIN_PLL_URL}`,
  stats: `https://stats.${MAIN_PLL_URL}`,
  championShipSeries: `https://${MAIN_PLL_URL}/championship-series`,
  academy: `https://${MAIN_PLL_URL}/academy`,
  bet: `https://${MAIN_PLL_URL}/bet`,
  assists: `https://${MAIN_PLL_URL}/pll-assists/`,
  media: `https://${MAIN_PLL_URL}/media/`,
  matchup: `https://${MAIN_PLL_URL}/matchup`,
  draftCentral: `https://${MAIN_PLL_URL}/draft-central/`,
  schedule: `https://${MAIN_PLL_URL}/schedule/`,
  tickets: `https://${MAIN_PLL_URL}/tickets`,
  transactions: `https://${MAIN_PLL_URL}/transactions/`,
  careers: `https://${MAIN_PLL_URL}/careers/`,
  pickem: `https://f2p.${MAIN_PLL_URL}`,
  bracket: `https://f2p.${MAIN_PLL_URL}/bracket`,
  watch: `https://plus.espn.com/pll`,
  allstar: `https://www.${MAIN_PLL_URL}/all-star`,
  injuryReport: `https://www.${MAIN_PLL_URL}/injury-report`,
  allTimeLeaders: '/career-leaders',
  standings: `https://${MAIN_PLL_URL}/standings`,
  generalAppLink: `https://pllmain.page.link/dl`,
  homepageAppLink: `https://pllmain.page.link/dla`,
  premierPass: `https://nft.${MAIN_PLL_URL}`,
  appFantasyLink: `https://pllmain.page.link/stats-fantasy`,
  whereToWatch: `https://${MAIN_PLL_URL}/where-to-watch`,
  plln: `https://pllmain.page.link/PLLN-Join-Web`,
  freeAgency: `https://${MAIN_PLL_URL}/free-agency`,
  fantasy: `https://f2p.${MAIN_PLL_URL}/fantasy`,
}

export const PLL_IMAGES = {
  pllShield: `${PLL_IMAGE_URL}/2019/03/pll_logo.png`,
  mainNavLogo: `${PLL_IMAGE_URL}/2021/01/PLL_navbar_logo.png`,
  pllAppFeaturedImage: `${PLL_IMAGE_URL}/2022/04/AppFormstack-scaled.jpg`,
  pllAppLogo: `https://img.${MAIN_PLL_URL}/Teams/2022/Logo/pll-app-icon.jpg`,
  fantasyShare: `https://${MAIN_PLL_URL}/wp-content/uploads/2023/05/IMG_7199-scaled.webp`,
}

export const DYNAMIC_LINK_CONFIG = {
  baseUrl: APP_LINK_BASE_URL,
  ibi: 'com.pll.PLL',
  isi: '1618489206',
  apn: 'com.pll.pllandroid',
}

export const TEAM_MENU = [
  {
    linkText: 'Boston Cannons',
    link: `${MAIN_PLL_HOME}/teams/boston-cannons`,
  },
  {
    linkText: 'California Redwoods',
    link: `${MAIN_PLL_HOME}/teams/california-redwoods`,
  },
  {
    linkText: 'Carolina Chaos',
    link: `${MAIN_PLL_HOME}/teams/carolina-chaos`,
  },
  {
    linkText: 'Denver Outlaws',
    link: `${MAIN_PLL_HOME}/teams/denver-outlaws`,
  },
  {
    linkText: 'Maryland Whipsnakes',
    link: `${MAIN_PLL_HOME}/teams/maryland-whipsnakes`,
  },
  {
    linkText: 'New York Atlas',
    link: `${MAIN_PLL_HOME}/teams/new-york-atlas`,
  },
  {
    linkText: 'Philadelphia Waterdogs',
    link: `${MAIN_PLL_HOME}/teams/philadelphia-waterdogs`,
  },
  {
    linkText: 'Utah Archers',
    link: `${MAIN_PLL_HOME}/teams/utah-archers`,
  }
]
export const MORE_MENU = [
  {
    linkText: 'All Time Leaders',
    link: PLL_LINKS.allTimeLeaders,
  },
  {
    linkText: 'Transactions',
    link: PLL_LINKS.transactions,
  },
  {
    linkText: 'Injuries',
    link: PLL_LINKS.injuryReport,
  },
  {
    linkText: 'PLL Nation',
    link: PLL_LINKS.allTimeLeaders,
  },
  {
    linkText: 'Draft',
    link: PLL_LINKS.draftCentral,
  },
  {
    linkText: 'Free Agency',
    link: PLL_LINKS.freeAgency,
  },
  {
    linkText: 'Careers',
    link: PLL_LINKS.careers,
  }
]

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]
export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
export const shortDays = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

export const homeActionItems = [
  {
    title: 'Players Table',
    link: '/player-table',
  },
  {
    title: 'Career Leaders',
    link: '/career-leaders',
  },
  {
    title: 'Advanced Stats',
    link: '/advanced-stats',
  },
]

export type Dropdown = {
label: string
value: string | number
}

export const yearDropdown = [
  { label: '2024', value: 2024 },
  { label: '2023', value: 2023 },
  { label: '2022', value: 2022 },
  { label: '2021', value: 2021 },
  { label: '2020', value: 2020 },
  { label: '2019', value: 2019 },
]

export const yearDropdownAllYears = [
  { label: 'All Years', value: 0 },
  { label: '2024', value: 2024 },
  { label: '2023', value: 2023 },
  { label: '2022', value: 2022 },
  { label: '2021', value: 2021 },
  { label: '2020', value: 2020 },
  { label: '2019', value: 2019 },
]

export const positionTypes = [
  { label: 'All', value: 'All' },
  { label: 'Attack', value: 'A' },
  { label: 'Midfield', value: 'M' },
  { label: 'Faceoff', value: 'FO' },
  { label: 'Short Stick D-Mid', value: 'SSDM' },
  { label: 'Long Stick Midfield', value: 'LSM' },
  { label: 'Defense', value: 'D' },
  { label: 'Goalie', value: 'G' }
]
